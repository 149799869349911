.container {
  display: flex;
  min-height: calc(100vh - 140px);
  align-content: flex-start;
  justify-content: center;
  padding: 0 0 50px;
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 100%;
  padding-top: 50px;
  flex-wrap: wrap;
}

.box h2 {
  font-size: 22px;
  font-weight: 500;
  margin: 0 auto 3vh ;
}

.companies {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 50px;
}

.company{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  max-width: 300px;
  padding: 2vh 2vw;
  box-sizing: border-box;
  color: white;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color);
}

.company p {
  width: 100%;
}

.company:hover {
  background-color: var(--secondary-color);
}
