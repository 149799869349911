.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.box {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 90%;
  text-align: left;
}

.box h2 {
  font-size: 22px;
  font-weight: 500;
  margin: 2vh auto 3vh;
}

.form {
  display: flex;
  justify-content: center;
}

.form label {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  width: 30%;
}

.form label span {
  display: flex;
  width: 100%;
  margin: 2vh auto 0;
}

.form label input {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  width: 85%;
  border: 2px solid var(--detail-color);
  border-radius: 8px 0 0 8px;
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 15%;
  border-radius: 0 8px 8px 0;
  min-height: 40px;
  font-size: 22px;
  font-weight: bold;
  background-color: var(--detail-color);
  color: white;
  cursor: pointer;
}

.form label span button span{
  background-image: url("/public/flat_icon_lupa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.invite_info {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.invite {
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--shadow-color);
  padding: 10px;
  border-radius: 10px;
}

.invite p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  min-width: 300px;
  min-height: 50px;
  font-size: 16px;
  padding: 5px;
  margin: 5px;
}

.invite p span {
  font-weight: bold;
}

.attemp_list h3,
.invite_info h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2vh;
  width: 100%;
  text-align: center;
}

.attemp_list {
  width: 100%;
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.attempt {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 16px;
  line-height: 5vh;
  color: #fff;
  margin-bottom: 2vh;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--detail-color);
}

@media (max-width: 860px) {
  .attempt {
    flex-direction: column;
    align-items: center;
  }
}
