.container {
  display: flex;
  min-height: calc(100vh - 140px);
  height: 100%;
  align-content: flex-start;
  justify-content: center;
  padding: 80px 0 40px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: black;
  max-width: 600px;
  width: 90%;
  text-align: center;
}

.box h2 { 
  font-size: 22px;
  font-weight: 500;
}


.box .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.box .form .not_found_message{
  background-color: red;
  color: white;
  font-size: bold;
  border-radius: 8px;
  height: 40px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box .form label {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  text-align: left;
  font-size: 18px;
  width: 100%;
  margin-top: 2vh;
}

.box .form label span {
  display: flex;
  width: 100%;
  margin-top:1vh;
}

.box .form label input {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  width: 85%;
  border: 2px solid var(--detail-color);
  border-radius: 8px 0 0 8px;
}


.box .form label button span {
  margin-top: 0;
}

.box button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 15%;
  border-radius: 0 8px 8px 0;
  min-height: 40px;
  font-size: 22px;
  font-weight: bold;
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
}

.box .invites_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  gap: 15px;
}
.showing_results{
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
  max-width: 600px;
}

.invite {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 16px;
  line-height: 5vh;
  color: #fff;
  padding: 0 15px;
  border-radius: 8px;
  box-sizing: border-box;
}

.invite_used {
  background-color: var(--contrast-color);
}
.invite_available {
  cursor: pointer;
  background-color: var(--detail-color);
}

.invite_available:hover {
  background-color: var(--shadow-color);
  color: #000;
}

.conteiner h2 {
  font-size: 14px;
}

.disable {
  color: rgb(0, 0, 0, 0.5);
  cursor: auto;
}

.goback_btn {
  position: absolute;
  box-sizing: border-box;
  top: 80px;
  right: 15px;
  height: 50px;
  width: 100px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--detail-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.goback_btn:hover {
  background-color: var(--shadow-color);
  color: var(--primary-color);
}

.lupa {
  background-image: url("/public/flat_icon_lupa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 860px) {
  .box .form label {
    width: 90%;
  }
  .invite {
    flex-direction: column;
    align-items: center;
  }

  .invite:hover {
    background-color: var(--shadow-color);
    color: #000;
  }
}
