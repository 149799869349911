body,
button,
input,
select,
textarea {
  font-family: 'Roboto', sans-serif;
}
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* reset.css */

/* Box sizing reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Margin and padding reset */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* List style reset */
ul,
ol {
  list-style: none;
}

/* Font reset */

/* Other resets */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Remove hyperlink styling */
a {
  text-decoration: none;
  color: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/* Remove border outline on focused elements */
:focus {
  outline: 0;
}
