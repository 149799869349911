.container {
  display: flex;
  min-height: calc(100vh - 140px);
  width: 80%;
  margin: 0 auto;
  align-content: flex-start;
  justify-content: center;
  padding: 50px 0 50px;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.box select {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
}

.box button {
  height: 50px;
  padding: 0 15px;
  /* background-color: #8ca8a3; */
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  transition: 0.2s;
  cursor: pointer;
}
.button_enable {
  background-color: var(--detail-color);
}
.button_desable {
  background-color: #8ca8a3;
}
.access_info h2 {
  color: black;
  font-size: 22px;
  font-weight: 500;
}
.access_info > div {
  display: flex;
  gap: 20px;
}

.event_list {
  color: black;
  font-size: 22px;
  font-weight: 500;
}

.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 80%;
  font-size: 16px;
}

.event,
.event_selected {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  max-width: 300px;
  padding: 30px 15px;
  box-sizing: border-box;

  color: white;
  border: none;
  border-radius: 8px;
}
.event {
  background-color: var(--primary-color);
}

.event:hover {
  background-color: var(--secondary-color);
}

.event_selected {
  background-color: var(--secondary-color);
  box-shadow: 5px 5px 10px #265a51;
}

.access_info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  align-self: center;
  justify-content: space-between;
  width: 100%;
}

.access_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.access_info h2 {
  width: 100%;
}

.access_info select {
  min-width: 125px;
  text-align: center;
}

@media (max-width: 695px) {
  /* .access_info {
    align-items: center;
  } */

  /* .access_info div h2 {
    width: 100%;
  } */

  /* .access_info div select {
    width: 100%;
  } */
  /* .box button {
    width: 30%;
  } */
}
