.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #002822;
    height: 70px;
    color: white;
    box-sizing: border-box;
}

.header nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.header img {
    width: 200px;
}

.header nav a {
    width: calc(100vh / 10 - 20px);
    display: flex;
    justify-content: space-between;
}

.header a p {
    color: white;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
}

.header a img {
    object-fit: contain;
    width: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    align-items: center;
}

.info p{
    font-size: 12px;
}

@media (max-width: 1300px) {
    .header nav {
        padding: 0 25px;
    }
}

@media (max-width: 690px) {
    .header nav a {
        display: flex;
        justify-content: center;
    }

    .header a p {
        display: none;
    }
}
