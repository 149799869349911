.container{
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: calc(100vh - 140px);
}
.box{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
}
.container h1{
    font-size: 56px;
    text-align: center;
}
.box_botao{
    display: flex;
    justify-content: center;
}
.botao{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    border: none;
    border-radius: 8px;
    font-size: 32px;
    font-weight: bold;
    max-width: 500px;
    width: 80%;
    background-color: var(--detail-color);
    color: white;
    cursor: pointer;
    text-align: center;
}
@media (max-width:600px) {
    .container h1{
        font-size: 32px;
    }
    .botao{
        font-size: 22px;
    }
}