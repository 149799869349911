.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    min-height: calc(100vh - 140px);
    height: 100%;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    height: 70px;
    width: 100%;
    align-items: center;
    background-color: white;
    border-radius: 0px 0px 8px 8px;
}

.btn_print {
    width: 130px;
    height: 50px;
    letter-spacing: 1.5px;
}

.btn_goback{
    position: absolute;
    box-sizing: border-box;
    top: 80px;
    right: 15px;
    height: 50px;
    width: 100px;
}

.btn_goback,
.btn_print {
    color: white;
    font-size: 16px;
    font-weight: 500;
    background-color: var(--detail-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn_goback:hover,
.btn_print:hover {
    background-color: var(--shadow-color);
    color: var(--primary-color);
}
.inpt_guest_name{
    width: 280px;
    height: 50px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    text-align: center;
}
.inpt_guest_role{
    width: 200px;
    height: 35px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    text-align: center;
}
.label{
    font-size: 24px;
}