.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.box {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 30px;
}

.box h2 {
  font-size: 22px;
  font-weight: 500;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 90%;
  flex-wrap: wrap;
  padding: 20px;
  border: 2px solid var(--detail-color);
  border-radius: 8px;
  gap: 10px;
}

.form .input_box {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  gap: 5px;
  width: 100%;
}

.form .input_box input, .form .input_box select {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  width: 100%;
  border: 2px solid var(--detail-color);
  border-radius: 8px;
}
.form .responsavel{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}
.form .responsavel .input_box{
  width: 70%;
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40%;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: var(--primary-color);
  cursor: pointer;
  margin: 0.5vh 0 2vh;
  transition: 0.2s;
}

.form button:hover {
  color: white;
  background: var(--detail-color);
}


/* 
@media (max-width: 600px) {
  .form {
    justify-content: center;
  }
  .form label {
    width: 90%;
  }
  .form > div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form div label span select {
    width: 100%;
  }
  .form button {
    width: 80%;
  }
} */
