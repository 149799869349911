:root {
  --primary-color: #276e61;
  --secondary-color: #388577;
  --detail-color: #4e857b;
  --shadow-color: #b7ceca;
  --contrast-color: #4e857bab;
}
.container {
  width: 100%;
  height: 100%;
}
