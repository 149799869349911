.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.box {
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.box h2 {
  font-size: 22px;
  font-weight: 500;
  margin: 2vh auto 3vh;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 90%;
  flex-wrap: wrap;
  padding: 20px;
  border: 2px solid var(--detail-color);
  border-radius: 8px;
}

.form label {
  min-width: 200px;
  display: flex;
  flex-direction: column;

  text-align: left;
  font-size: 18px;
  width: 100%;
}

.form label span {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.form label input {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  width: 100%;
  border: 2px solid var(--detail-color);
  border-radius: 8px;
  margin: 0.5vh 0 2vh;
}

.form label select {
  box-sizing: border-box;
  width: 50%;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  border: 2px solid var(--detail-color);
  border-radius: 8px;
  margin: 0.5vh 0 2vh;
}

.form > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40%;
  height: 50px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: var(--primary-color);
  cursor: pointer;
  margin: 0.5vh 0 2vh;
  transition: 0.2s;
}

.form button:hover {
  color: white;
  background: var(--detail-color);
}

.invite_info {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  margin-bottom: 2vh;
  width: 100%;
  text-align: center;
}

.invite_info h3{
  font-weight: 500;
  font-size: 16px;
  margin: 1vh auto 2vh;
}

.invite {
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--shadow-color);
  padding: 10px;
  border-radius: 10px;
}

.invite p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  min-width: 300px;
  min-height: 50px;
  font-size: 16px;
  padding: 5px;
  margin: 5px;
}

.invite p span {
  font-weight: bold;
}


.lupa {
  background-image: url("/public/flat_icon_lupa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20;
}

@media (max-width: 600px) {
  .form {
    justify-content: center;
  }
  .form label {
    width: 90%;
  }
  .form > div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .form div label span select {
    width: 100%;
  }
  .form button {
    width: 80%;
  }
}
