/* dashboardStyle.module.css */

.contagem {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f3f3f3;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contagem h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

.contagem_total,
.contagem_hora,
.contagem_categoria,
.contagem_setor {
    margin-bottom: 30px;
}

.contagem_total h2,
.contagem_hora h2,
.contagem_categoria h,
.contagem_setor h2{
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.contagem_total h3,
.contagem_hora h3,
.contagem_categoria h3,
.contagem_setor h3 {
    font-size: 1.25rem;
    font-weight: bold;
}

.contagem_categoria div,
.contagem_setor div {
    margin-top: 10px;
    padding-left: 20px;
    border-left: 4px solid var(--secondary-color);
}

.contagem_categoria h3,
.contagem_setor h3 {
    font-size: 1.1rem;
    font-weight: normal;
}

