.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  min-height: calc(100vh - 140px);
  height: 100%;
  padding: 70px 0 0px;
}

.container h2 {
  font-size: 22px;
  text-align: center;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 20px;
  border-radius: 8px;
  width: 80%;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.codigo_invalido {
  padding: 10px;
  border-radius: 8px;
  background-color: #dc5b3e;
}

.input_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  max-width: 800px;
  width: 90vh;
}

.input_box > div {
  display: flex;
  flex-direction: column;
}

.input_box span {
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 10px;
}
.leitura_rapida{
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}
.leitura_rapida input{
  width: 20px;
  height: 20px;
}

.input_box button span {
  margin-top: 0;
}

.pesquisar_midia input {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 100%;
  width: 85%;
  border: 2px solid var(--detail-color);
  border-radius: 5px 0 0 5px;
}

.input_box button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 15%;
  border-radius: 0 5px 5px 0;
  height: 100%;
  width: 20%;
  font-weight: 500;
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
}

.render {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  width: 90vw;
  max-width: 400px;
  height: 300px;
}

.render div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.render button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px dotted red;
  font-size: 16px;
  font-weight: bold;
  background-color: white;
  color: red;
  cursor: pointer;
  min-height: 40px;
  width: 90%;
}

.goback_btn {
  position: absolute;
  box-sizing: border-box;
  top: 80px;
  right: 15px;
  height: 50px;
  width: 100px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--detail-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.goback_btn:hover{ 
  background-color: var(--shadow-color);
  color: var(--primary-color);
}
.leitura_rapida{
  display: flex;
}
.lupa {
  background-image: url("/public/flat_icon_lupa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.render_active {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 10px;
  border: none !important;
}

.render_active div:first-child img {
  display: none;
}

.render_active div:nth-child(2) video {
  border-radius: 10px;
}

.render_active span select {
  width: 80%;
  height: 50px;
  border-radius: 10px;
  border-color: var(--detail-color);
  font-size: 16px;
}

.render_active span button {
  margin-top: 10px;
  border: none;
  background-color: var(--secondary-color);
  color: white;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  transition: 0.2s;
}

.render_active span button:hover {
  background-color: var(--contrast-color);
}
