.container {
    font-family: 'Roboto', Arial, sans-serif;
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 300px;
    margin-bottom: 20px;
}

.header {
    color: #3db9e4;
    margin-bottom: 20px;
    font-size: 2.5em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
}

.loader {
    border: 6px solid #ecf0f1;
    border-top: 6px solid #3db9e4;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px;
}

.loaderText {
    color: #72b842;
    font-size: 1.2em;
}

.invitesContainer {
    margin-top: 20px;
    width: 80%;
    max-width: 800px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.subHeader {
    color: #3db9e4;
    margin-bottom: 15px;
    font-size: 1.5em;
    border-bottom: 2px solid #72b842;
    padding-bottom: 10px;
}

.listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.listColumn {
    flex: 0 0 48%;
    padding: 10px;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.listItem {
    font-size: 1.1em;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ecf0f1;
    border-radius: 6px;
    background-color: #f8f9fa;
    transition: transform 0.2s, box-shadow 0.2s;
}

.listItem:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.listItemSorteado {
    font-weight: bold;
    color: #72b842;
    background-color: #eafaf1;
    border-color: #72b842;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
