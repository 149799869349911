.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.search_invite {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  text-align: left;
}

.search_invite h2 {
  font-size: 22px;
  font-weight: 500;
  margin: 2vh auto;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.form label {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  width: 30%;
}

.form label span {
  display: flex;
  width: 100%;
  margin-top: 1vh;
}

.form label span button span {
  margin: 0;
}

.form label input {
  box-sizing: border-box;
  padding: 10px;
  color: black;
  font-size: 16px;
  height: 50px;
  width: 85%;
  border: 2px solid var(--secondary-color);
  border-radius: 8px 0 0 8px;
}

.form button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 15%;
  border-radius: 0 8px 8px 0;
  min-height: 40px;
  font-size: 20px;
  font-weight: bold;
  background-color: var(--secondary-color);
  color: white;
  cursor: pointer;
}

.invites_list {
  width: 100%;
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.invites_list h3,
.invite_info h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2vh;
}

.invite {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  font-size: 16px;
  line-height: 5vh;
  color: #fff;
  margin-bottom: 2vh;
  border-radius: 5px;
  cursor: pointer;
}

.invite_used {
  background-color: var(--contrast-color);
}
.invite_available {
  background-color: var(--detail-color);
}

.invite:hover {
  background-color: var(--shadow-color);
  color: #000;
}

.disable {
  color: rgb(0, 0, 0, 0.5);
  cursor: auto;
}

.invite_info {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.invite_present {
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: var(--shadow-color);
  padding: 10px;
  border-radius: 10px;
}

.invite_present p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 390px;
  min-width: 300px;
  min-height: 50px;
  font-size: 16px;
  padding: 5px;
  margin: 5px;
}

.invite_present p span {
  font-weight: bold;
}

.lupa {
  background-image: url("/public/flat_icon_lupa.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 860px) {
  .search_invite {
    text-align: center;
  }
  .form {
    justify-content: center;
  }
  .form label {
    width: 90%;
  }
  .invite {
    flex-direction: column;
    align-items: center;
  }

  .invite:hover {
    background-color: var(--shadow-color);
    color: #000;
  }
}
