.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 140px);
  height: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  max-width: 600px;
  width: 90%;
  text-align: center;
  background-color: white;
  /* border: 2px solid var(--detail-color); */
  padding: 10px;
  gap: 10px;
  border-radius: 8px;
}

.answer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #585858;
  padding: 25px 20px 25px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  height: 300px;
}

.answer p {
  margin: 1vh 0;
}

.title {
  font-size: 22px;
  font-weight: bold;
}

.subtitle {
  font-size: 18px;
}

.message {
  padding-bottom: 10px;
  width: 80%;
}

.success {
  background-color: var(--primary-color);
}

.error {
  background-color: #dc5b3e;
}

.warning {
  background-color: #ece257;
  color: black;
}

.consumed {
  background-color: #52aad3;
  color: white;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  height: 70px;
  width: 100%;
  align-items: center;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
}

.buttons button {
  width: 130px;
  height: 50px;
  letter-spacing: 1.5px;
}

.goback_btn {
  position: absolute;
  box-sizing: border-box;
  top: 80px;
  right: 15px;
  height: 50px;
  width: 100px;
}

.goback_btn,
.buttons button {
  color: white;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--detail-color);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.goback_btn:hover,
.buttons button:hover {
  background-color: var(--shadow-color);
  color: var(--primary-color);
}
