.footer{
    height: 70px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(52deg,  rgba(19,41,37,16) 0%,rgba(140,168,163,66) 100%);
    font-size: 12px;
    color: white;
    text-align: center;
}
.footer h1{
    font-size: 28px;
}
@media (max-width: 600px) {
    .footer h1{
        font-size: 16px;
    }
}