.container {
  display: flex;
  align-content: flex-start;
  min-height: calc(100vh - 140px);
}
.nav {
  width: 250px;
  box-sizing: border-box;
  border-right: 2px solid var(--detail-color);
  background-color: white;
}
.nav ul {
  display: flex;
  flex-direction: column;
  color: var(--detail-color);
  font-size: 18px;
  font-weight: bold;
}
.nav li {
  font-size: 18px;
  font-weight: 400;
  padding: 20px 5px;
  text-align: center;
  border-bottom: 2px solid var(--detail-color);
  cursor: pointer;
  transition: 0.2s;
}
.nav li:hover {
  color: white;
  background-color: var(--secondary-color);
}

.toggle_visibility {
  position: absolute;
  bottom: 90px;
  left: 5px;
  background-color: var(--detail-color);
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
}

.container > h1 {
  font-size: 42px;
  text-align: center;
}

.box {
  width: 100%;
  padding: 50px 0;
}
