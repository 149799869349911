@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 140px);
  align-content: center;
  justify-content: center;
}
.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 400px;
  background:#35534E;
  color: white;
  text-align: center;
  font-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px 0px 50px;
  border-radius: 8px;
}
.form > div{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  gap: 15px;
}

.form > h2 {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 2vh;
}

.form label {
  display: flex;
  width: 100%;
  justify-content: left;
}

.form input {
  height: 50px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin: 0 auto 15px auto;
  font-size: 16px;
}

.password {
  width: 100%;
  display: flex; 
  align-items: center;
}
.password > div{
  width: 100%;
}
.password input {
  width: 90%;
  background-color: white;
  margin: 0;
}
.password span{
  width: 10%;
}

.form button {
  border: none;
  border-radius: 8px;
  height: 50px;
  width: 60%;
  margin: 0 auto;
  color: black;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s;
}

.form button:hover {
  color: white;
  background-color: #8CA8A3;
}

.mensagem_erro {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; 
}
.mensagem_erro h2{
  font-size: 22px;
  background-color: rgb(255, 164, 164);
  padding: 5px;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .container {
    width: 100vw;
  }
  
  .formContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
  
  .formContainer .form {
    width: 90%;
  }
  .mensagem_erro h2{
    font-size: 16px;
  } 
}
