.container {
  display: flex;
  min-height: calc(100vh - 140px);
  width: 70%;
  margin: auto;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.boxes {
  width: 33%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxes img {
  width: 150px;
}

.boxes div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxes button {
  border: none;
  border-radius: 8px;
  min-height: 50px;
  font-size: 16px;
  padding: 5px 15px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  margin-top: 3vh;
}

.boxes button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .boxes {
    margin: 3vh;
    width: 100%;
    height: 50%;
  }
}
